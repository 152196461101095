import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { httpCookies } from "helpers/httpCookies";
import { ACCESS_TOKEN_KEY, ROLE_KEY } from "config/app.config";

const AdminProtected = () => {
  const token = httpCookies.get(ACCESS_TOKEN_KEY);
  const role = httpCookies.get(ROLE_KEY);

  if (token) {
    if (role && role === "admin") {
      return <Outlet />;
    } else {
      return <Navigate to="/not-found" />;
    }
  }

  return <Navigate to="/login" />;
};

export default AdminProtected;
